import React, { createContext } from "react";

import { useEffect, useState } from "react";

import Mine from './Mine';

import { ethers } from "ethers";
import config from './config';
import BlockchainContext from "./BlockchainContext";
import podzAbi from './abi/contracts/Podz.sol/Podz.json';

let provider = null;

export default function App() {

  const [connection, setConnection] = useState(false);
  const [accounts, setAccount] = useState('');
  const [network, setNetwork] = useState('');
  const [minted, setMinted] = useState('...');
  const [podz, setPodz] = useState(undefined);
  const [provider, setProvider] = useState(undefined);

  async function setPodzContract() {
    let signer = await provider.getSigner();
    setPodz(new ethers.Contract(config.contractAddress, podzAbi, signer));
  }

  async function connect() {

    if (provider) {
    try {
      await provider.send("eth_requestAccounts", []);

      // get network and address

      let getNetwork = await provider.getNetwork();
      setNetwork(getNetwork.chainId);
      setAccount(await provider.listAccounts());
      setConnection(true);

      await setPodzContract();

      await setEmitters();
    } catch (err) {
      console.log("Error getting permission", err);
    }
  }
  }

  function setEmitters() {
    console.log("setting emitters");

    window.ethereum.on("accountsChanged", (acc) => {
      if (acc == undefined || acc.length == 0) {
        setConnection(false);
      }
      console.log("accounts have changed to", acc[0]);
      setAccount(acc);
    });

    window.ethereum.on("chainChanged", (chain) => {
      setNetwork(parseInt(Number(chain)));
      console.log("CHAIN CHANGED", "chain:", chain);
    })
  }

  useEffect(async () => {
    console.log("new provider?")
    setProvider(new ethers.providers.Web3Provider(window.ethereum, "any"));
  }, []);

  useEffect(async () => {
    console.log("provider changed");
    connect();
  }, [provider]);

  useEffect(async() => {
    console.log("podz contract initialized");
    if (podz != undefined) {
      setMinted(parseInt(Number(await podz.tokensMinted())));
    }
  }, [podz,network])

  function heading(title, description) {
    return (
      <div className="w-full px-6">
        <h3 className="text-3xl sm:text-4xl font-semibold tracking-tighter leading-none mb-3">{title}</h3>
        <p className="text-lg sm:text-xl leading-normal text-gray-700 mb-8">{description}</p>
      </div>
    )
  }

  return (
    <BlockchainContext.Provider value={{accounts, network, podz}}>
      <div className="overflow-y-scroll h-full font-sans">

        <header className="absolute top-0 left-0 w-full py-4">
          <div className="flex items-center justify-between px-8">
            <span className="text-2xl tracking-tighter font-semibold">
              PODZ
            </span>
            <div className>
              <div className="flex items-center">
                <a className="text-lg leading-normal text-blue-500 hover:text-blue-600 no-underline ml-8" href="https://twitter.com/cryptogiddy"><img src='/twitter.svg' className="w-6" /></a>
                <a className="text-lg leading-normal text-blue-500 hover:text-blue-600 no-underline ml-8" href="https://discord.gg/pXMV5ZzEge"><img src='/discord.svg' className="w-6" /></a>
                <div className="text-lg leading-normal text-blue-500 hover:text-blue-600 no-underline ml-8 cursor-pointer" onClick={connect}>{
                  connection ? "Connected" : "Connect"
                }</div>
              </div>
            </div>
          </div>
        </header>
        <section className="bg-purple-200 h-full py-8">
          <div className="w-5/6 max-w-lg ml-auto mr-auto h-full">
            <div className="flex items-center justify-center text-center h-full">
              <div>
                <img src="/podz-imgs/podz-5.svg" width="250px" className="mx-auto rounded-md mt-10 shadow-lg" />
                <h1 className="text-4xl sm:text-5xl font-semibold leading-none tracking-tighter mb-4 mt-5">PODZ</h1>
                <h2 className="text-2xl sm:text-3xl text-blue-800 opacity-75 font-normal leading-tight">Customize completely on-chain NFTs on the POLYGON blockchain.</h2>
                <div className="text-xl sm:text-xl text-white bg-purple-600 font-mono leading-tight p-3 m-3 rounded-lg">{minted}/10000 MINTED</div>
                <div className="flex flex-col sm:flex-row items-center justify-center sm:pt-3 mb-3 sm:mb-6">
                  <a className="flex items-center text-xl leading-none text-blue-500 hover:text-blue-600 no-underline mb-2 sm:mb-0 sm:mr-4" href="https://opensea.io/collection/get-podz">
                    <div className="mr-3">
                      <img src='/opensea.svg' className="w-5" />
                    </div>
                    <span>
                      Opensea
                    </span>
                  </a>
                  <a className="flex items-center text-xl leading-none text-blue-500 hover:text-blue-600 no-underline mt-2 sm:mt-0 sm:ml-4" href="https://polygonscan.com/address/0x56126700df0b2c27fb2454f386e5aa898f2e9b90">
                    <div className="mr-3">
                      <img src='/matic.svg' className="w-5" />
                    </div>
                    <span>
                      PolygonScan
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white py-8">
          <div className="w-5/6 max-w-lg ml-auto mr-auto mt-8 mb-8">
            <div className="my-6">
              {heading(
                "What is PODZ?",
                "PODZ is an experimental NFT project that is completely on-chain and customizable by YOU. No IPFS or centralized services are used for any of the images."
              )}
              {heading("What is Polygon?", "Polygon is a Layer 2 solution that has nearly no gas fees. Minting on Ethereum would take anywhere from $30 to $50+, comparably on Polygon it will take less than a cent.")}
              {heading("How does minting work?", "To mint a PODZ you need to select a unique seed that hashes with the search seed found on the contract. If the beginning of the hash starts with 'beef' it will be considered a valid seed and can be used to mint a token.")}
              <div className="w-full px-6">
                <h3 className="text-lg font-semibold tracking-tighter leading-none mb-1">Disclaimer</h3>
                <p className="text-md leading-normal text-gray-700 mb-8">This code is not audited. Play at your own risk.</p>
              </div>
              {/*<div className="w-full sm:w-1/2 px-6 py-6">
                <div className="mb-8">
                  <svg className="align-middle text-teal-500" xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><circle cx={12} cy={12} r={10} /><line x1="14.31" y1={8} x2="20.05" y2="17.94" /><line x1="9.69" y1={8} x2="21.17" y2={8} /><line x1="7.38" y1={12} x2="13.12" y2="2.06" /><line x1="9.69" y1={16} x2="3.95" y2="6.06" /><line x1="14.31" y1={16} x2="2.83" y2={16} /><line x1="16.62" y1={12} x2="10.88" y2="21.94" /></svg>
                </div>
                <h3 className="text-3xl sm:text-4xl font-semibold tracking-tighter leading-none mb-3">This is a small heading</h3>
                <p className="text-lg sm:text-xl leading-normal text-gray-700 mb-8">If you don't plan to use the shadow utilities in your project, you can disable them entirely.</p>
                <a className="text-lg leading-normal text-blue-500 hover:text-blue-600 no-underline" href="#">Link to something</a>
              </div>
              */}
            </div>
          </div>
        </section>

        {
          connection ?
          (network) == config.network ? <Mine/> : <div className="text-center"> Please change network to Polygon </div>
          :
          <div className="text-center"> Please connect your wallet </div>
        }

        <footer className="bg-gray-100 py-8 mt-8">
          <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between px-8">
            <small className="block text-sm text-gray-500">
              Made by <a href="https://twitter.com/cryptogiddy" >@cryptogiddy</a>
            </small>
            <div className="mb-4 md:mb-0">
              <div className="flex flex-col md:flex-row md:items-center">
                <a className="text-sm md:text-lg leading-normal text-gray-700 hover:text-gray-900 no-underline mb-4 md:mb-0 md:ml-8" href="#">{config.contractAddress}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </BlockchainContext.Provider>
  );
}