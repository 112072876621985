import React from "react";
import { ethers } from 'ethers';
import { useEffect, useState, useContext } from 'react';
import config, { searchSeed } from "./config";
import podzAbi from "./abi/contracts/Podz.sol/Podz.json";
import BlockchainContext from "./BlockchainContext";

let signer = null;
let podz = null;

export default function Mine({ provider }) {

    const [foundHash, setHash] = useState("");
    const [mining, setMining] = useState(false);
    const [podzSVG, setSVG] = useState("");
    const [podzProperties, setProperties] = useState("");
    const [owner, setOwner] = useState("");
    const [message, setMessage] = useState("");
    const [free, setFree] = useState(0)

    const blockchainContext = useContext(BlockchainContext);

    const {accounts, network, podz} = blockchainContext;

    useEffect(() => {
        console.log("mining has changed", mining);
    }, [mining]);

    async function grabData() {
        try {
        console.log("Grabbing data please wait..");
        if (foundHash != ''){
        let svg = await podz.generateSVG(foundHash);
        // console.log(svg);
        let properties = await podz.generateProperties(foundHash);
        let owner = await podz.podzOwners(foundHash);
        // console.log(properties);
        // console.log(owner);
        setProperties(JSON.parse("[" + properties + "]"));
        setSVG(svg);
        setOwner(owner);
        setMining(false);
        let freeMints = await podz.VIPmint(accounts[0]);
        setFree(parseInt(Number(freeMints)));
        }
        } catch(err) {
            setMessage(err.message + " " + ((err.data) ? err.data.message : ""));
            setMining(false);
        }
    }

    async function mint() {
        setMessage('');
        try {
            const mintTx = await podz.normalMint(foundHash, { value: ethers.utils.parseEther("5") });
            console.log('normal mint tx', mintTx);
            await grabData();
        } catch (err) {
            setMessage(err.message + " " + ((err.data) ? err.data.message : ""));
        }
    }

    async function VIPmint() {
        setMessage('');
        try {
            const mintTx = await podz.vipMint(foundHash);
            console.log('vip mint tx', mintTx);
            await grabData();
        } catch (err) {
            setMessage(err.message + " " + ((err.data) ? err.data.message : ""));
        }
    }


    useEffect(async () => {
        console.log("new hash");

        await grabData();
    }, [foundHash]);

    async function lookForHash() {
        let beef = false;
        let seed = ethers.BigNumber.from(config.searchSeed);
        console.log("Look for 🐄 beef...");

        while (!beef) {
            let randBytes = ethers.utils.randomBytes(32);
            let hash = ethers.utils.keccak256([...ethers.utils.arrayify(seed), ...randBytes]);

            if (hash.slice(2, 6) == "beef") {
                beef = true;
                console.log("found beef: ", hash, ethers.utils.hexlify(randBytes));
                setHash(ethers.utils.hexlify(randBytes));

            }
        }
    }
    async function startMining() {
        setMining(true);
        setMessage('');


        // setTimeout for long functions (parralel?)
        setTimeout(lookForHash);
    }


    return (
        <div className="max-w-lg mx-auto">

            {podzSVG && 
            <div className="text-center">
                <div>Search Seed</div>
                {/*<div>{config.searchSeed.substring(0, 10) + '...' + config.searchSeed.substring(config.searchSeed.length - 10)}</div> */}
                <div className="text-xs">{config.searchSeed}</div>
            </div>
            }

            {podzSVG && 
            <div className="text-center">
                <div>Found</div>
                {/*<div>{config.searchSeed.substring(0, 10) + '...' + config.searchSeed.substring(config.searchSeed.length - 10)}</div> */}
                <div className="text-xs">{foundHash}</div>
            </div>
            }

            {podzSVG && 
            <div className="text-center">
                <div>Owner</div>
                {/*<div>{config.searchSeed.substring(0, 10) + '...' + config.searchSeed.substring(config.searchSeed.length - 10)}</div> */}
                <div className="text-xs">{owner}</div>
            </div>
            }
            
            {podzSVG &&
                <div className="my-5 mx-auto rounded-md shadow-lg" dangerouslySetInnerHTML={{ __html: podzSVG }}>

                </div>
            }

            {/*JSON.stringify({ podzProperties })*/}


            {podzProperties &&
                <div>
                    <div className="flex flex-wrap text-center justify-center">
                    {podzProperties.map((i, k) => {
                        return (
                        <div key={k} className="flex- 1 p-3 m-2 w-32 bg-purple-500 rounded-lg shadow-md">
                            <div className="font-semibold text-white"> {i.trait_type} </div>
                            <div className="text-white"> {i.value} </div>
                        </div>
                        )
                    })}
                    </div>



                    <div className="flex my-3 justify-center">
                        <div className="m-2 bg-pink-600 text-white p-3 rounded text-center text-xl cursor-pointer" onClick={() => mint()}>
                            <div className="font-semibold">Mint</div>
                            <div className="text-sm">5 MATIC</div>
                            </div>
                        <div className="m-2 bg-yellow-600 text-white p-3 rounded text-center text-xl cursor-pointer" onClick={() => VIPmint()}>
                            <div className="font-semibold">VIP Mint</div>
                            <div className="text-sm">{free} left</div>
                            </div>
                    </div>

                    
                </div>
            }




            {/*JSON.stringify(mining)*/}

            {
                !mining ?
                    <div className="my-3 bg-purple-500 font-semibold text-white mx-auto p-5 rounded text-center text-xl cursor-pointer" onClick={() => { startMining() }}>MINE</div>
                    :
                    <div className="my-3 bg-gray-300 font-semibold text-white mx-auto p-5 rounded text-center text-xl disabled">Mining</div>
            }
            <div className="text-sm text-purple-500 font-semibold">{message}</div>

            {/* <div className="cursor-pointer" onClick={() => setMining(!mining)}>COOLO</div>
            <div className="mx-auto text-center">
                current seed: {currentSeed}
                found hash: {foundHash}
            </div> */}



        </div>
    );
}